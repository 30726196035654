<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('balanceHistory') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :license="license"
    :request="request"
    :is-search="false"
    :csv-handler="downloadCsvHandler"
    :sort="'desc'"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table v-else :license="license" :list="list"></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { store } from '@/application/store'
import { getBalanceHistory } from '@/application/services/billing'
import { useCsvExport } from '@/application/composables/csvExport.js'
import { formatterCSVBillingBalanceHistory } from '@/application/utils/csv.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'HistroyPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/BalanceHistoryTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const i18n = useI18n()
    const license = computed(() => store.getters.getLicense)
    const {
      downloadCsvHandler,
    } = useCsvExport(
      'billing-balance-history.csv',
      formatterCSVBillingBalanceHistory,
      {
        messages: i18n.t('forms.billing.rows.messagesCount'),
        package_messages: i18n.t('forms.billing.rows.packageMessagesCount'),
        sandbox_messages: i18n.t('forms.billing.rows.sandboxMessagesCount'),
        package_sandbox_messages: i18n.t('forms.billing.rows.packageSandboxMessagesCount'),
        create_date: i18n.t('forms.billing.rows.dateCreate'),
        expire_date: i18n.t('forms.billing.rows.expiryDate'),
      }
    )
    return {
      license,
      request: getBalanceHistory,
      downloadCsvHandler,
    }
  },
}
</script>
